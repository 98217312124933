.pagination>li {
  display: inline-block;
  padding-left: 0;

}

.pagination>li {
  list-style: none;
  /* border: 0.9px solid; */
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  text-decoration: none;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  background: #FFFFFF;
  /* grey */

  border: 1px solid #AFB3BD;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
  margin: 0 2px;
}

.pagination>li.active>a {
  color: #fff;
  background-color: #AFB3BD;
  border-color: #AFB3BD;
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
  background-color: #AFB3BD;
  color: white;
}
